const dep = require('../node_modules/jarallax/dist/jarallax')

jarallax(document.querySelectorAll('.has-parallax'), {
    speed: 0.1
});

document.addEventListener('DOMContentLoaded', () => {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach(el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                // el.classList.toggle('is-active');
                $target.classList.toggle('is-visible');

            });
        });
    }

    // Get all "modal-exit" elements
    const $modalClose = Array.prototype.slice.call(document.querySelectorAll('.modal-close'), 0);

    // Check if there are any mobal close
    if ($modalClose.length > 0) {

        // Add a click event on each of them
        $modalClose.forEach(el => {
            el.addEventListener('click', () => {

                // Find the corresponding modal and toggle class
                const $parentModal = el.closest('.modal');
                $parentModal.classList.toggle('is-visible');

            });
        });

    }

});